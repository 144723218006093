import React, { useState } from "react";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
import { postApi, showNotification } from "../../Repository/Apis";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UpdatePassword = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const togglePasswordVisibility = (field) => {
    if (field === "old") setShowOldPassword(!showOldPassword);
    if (field === "new") setShowNewPassword(!showNewPassword);
    if (field === "confirm") setShowConfirmPassword(!showConfirmPassword);
  };

  const resetFields = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };

  const submitHandler = () => {
    const validatePassword = (password) => {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#._])[A-Za-z\d@$!%*?&#._]{8,}$/;
      return passwordRegex.test(password);
    };
    if (
      !newPassword?.trim() ||
      !confirmNewPassword?.trim() ||
      !oldPassword?.trim()
    ) {
      showNotification({
        message: "Passwords fields cannot be empty",
        type: "danger",
      });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      showNotification({
        message: "Confirm password does not match the password",
        type: "danger",
      });
      return;
    }

    if (!validatePassword(newPassword?.trim())) {
      showNotification({
        message:
          "Password must be 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
        type: "danger",
      });
      return;
    }

    try {
      postApi(setLoading, `change-password`, {
        password: oldPassword,
        newPassword,
        confirmPassword: confirmNewPassword,
      });
      resetFields();
      props.onHide()
    } catch (error) {
      const msg = error?.response?.data?.message || "An error occurred";
      showNotification({ message: msg, type: "danger" });
      props.onHide()
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          resetFields();
          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <h5 className="fw-bold mb-0">Change Profile Password</h5>
        </Modal.Header>
        <ModalBody>
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">Old Password</Form.Label>
            <Form.Control
              type={showOldPassword ? "text" : "password"}
              name="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder={`Old Password`}
            />
             <span
              className="input-group-text"
              onClick={() => togglePasswordVisibility("old")}
              style={{
                position: "absolute",
                top: "18%",
                display: "inline-block",
                backgroundColor: "#fff",
                right: "20px",
                cursor: "pointer",
              }}
            >
              {showOldPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">New Password</Form.Label>
            <Form.Control
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={`New Password`}
            />
             <span
              className="input-group-text"
              onClick={() => togglePasswordVisibility("new")}
              // style={{ cursor: "pointer" }}
              style={{
                position: "absolute",
                top: "48%",
                display: "inline-block",
                backgroundColor: "#fff",
                right: "20px",
                cursor: "pointer",
              }}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">Confirm New Password</Form.Label>
            <Form.Control
               type={showConfirmPassword ? "text" : "password"}
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder={`Confirm New Password`}
            />
            <span
              className="input-group-text"
              onClick={() => togglePasswordVisibility("confirm")}
              style={{
                position: "absolute",
                top: "77%",
                display: "inline-block",
                backgroundColor: "#fff",
                right: "20px",
                cursor: "pointer",
              }}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
        </ModalBody>
        <Modal.Footer className="justify-content-center">
          <Button className="theme-button" onClick={submitHandler}>
            SAVE
          </Button>
          <Button className="theme-button-outline" onClick={props.onHide}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePassword;
